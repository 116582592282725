<template>
  <div class="morph-new" v-loading.fullscreen.lock="loading">
    <el-row>
      <ai-breadcrumb />
    </el-row>
    <el-form class="form" ref="form" :model="form" size="small" label-width="180px">
      <el-form-item :label="$t('gpt.desc')">
        <el-input v-model="form.desc" size="small" style="width: 30%"></el-input>
      </el-form-item>
      <el-form-item class="source-img-form-item" :label="$t('gpt.sourceImage')" required>
        <el-upload :action="aiApiRoot + '/v1/images'" accept=".jpg, .jpeg, .png"
          :headers="{ Authorization: this.token }" list-type="picture-card" :file-list="fileList"
          :on-change="handleChange" :on-remove="handleRemove" :data="{ group: 'gpt' }" :multiple="true">
          <i class="el-icon-plus"></i>
        </el-upload>
      </el-form-item>
      <el-form-item :label="$t('gpt.targetFace')" required>
        <el-button :disabled="form.targetIds.length >= 2" icon="el-icon-plus" size="mini" type="primary" circle
          @click="clickAdd"></el-button>
        <el-card v-for="(_, idx) in form.targetIds" :key="'target-' + idx" class="target-container" shadow="none"
          style="width: 80%">
          <div slot="header" class="clearfix">
            <span>Target {{ idx + 1 }}</span>
            <el-button :disabled="form.targetIds.length <= 1" icon="el-icon-minus" size="mini"
              style="float: right;margin-top: 2px;" circle type="danger" @click="clickRemove(idx)"></el-button>
          </div>
          <el-row :gutter="5">
            <el-col class="target-block" :span="4" v-for="item in targets[idx]" :key="item.id">
              <el-row>
                <el-radio v-model="form.targetIds[idx]" :label="item.id">{{ item.name }}</el-radio>
              </el-row>
              <el-row>
                <el-image class="target-img" :src="item.url | imageUrl(false)"
                  :preview-src-list="[imageUrl(item.url, false)]" fit="contain" />
              </el-row>
            </el-col>
          </el-row>
          <el-row>
            <pagination :key="'pagination-' + idx" v-show="total > 0" :total="total"
              :offset.sync="listQuery[idx].offset" :limit.sync="listQuery[idx].limit" layout="prev, pager, next "
              @pagination="fetchData(idx)" />
          </el-row>
        </el-card>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-s-promotion" @click="onSubmit">{{ $t("common.submit") }}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { createTask, fetchTargets } from "@/api/gpt";
import Pagination from "@/components/Pagination";
import AiBreadcrumb from "@/components/AiBreadcrumb";
import { getToken } from "@/utils/auth";
import { aiApiRoot } from "@/utils/server";
import { imageUrl } from "@/filters";

export default {
  components: {
    Pagination,
    AiBreadcrumb,
  },
  data() {
    return {
      token: "Bearer " + getToken(),
      aiApiRoot,
      targets: [[], []],
      listQuery: [{
        offset: 0,
        limit: 12,
        group: "morph-face"
      }, {
        offset: 0,
        limit: 12,
        group: "morph-face"
      }],
      total: 0,
      form: {
        desc: "",
        targetIds: [],
      },
      fileList: [],
      loading: false,
    };
  },
  methods: {
    initForm() {
      this.form = {
        desc: "",
        targetIds: [1],
      }
    },
    onSubmit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.fileList.length === 0) {
            this.$message({
              message: "Please upload source image",
              type: "warning",
            });
            return;
          }
          let params = {
            ...this.form, category: "morph", sourceIds: []
          }
          let finish = true;
          this.fileList.forEach((t) => {
            if (t.status !== "success") {
              finish = false;
              return;
            }
            if (
              t.response &&
              t.response.result &&
              t.response.result.length > 0
            ) {
              params.sourceIds.push(t.response.result[0].id);
            }
          });
          if (!finish) {
            this.$message({
              message: "Image upload not finish, can't submit",
              type: "warning",
            });
            return;
          }
          this.$confirm(
            this.$t("tagger.submitTask"),
            this.$t("common.pleaseConfirm"),
            {
              confirmButtonText: "OK",
              cancelButtonText: "Cancel",
              type: "warning",
            }
          ).then(() => {
            this.loading = true;
            createTask(params).then((response) => {
              this.loading = false;
              if (response.success) {
                this.$notify({
                  title: this.$t("common.saveSucc"),
                  message: this.$t("common.indirecting"),
                  type: "success",
                });
                let suffix = response.result && response.result.id ? "/" + response.result.id : ""
                this.$router.push("/services/gpt-morph/morph" + suffix).catch(() => { });
              }
            })
              .catch(() => {
                this.loading = false;
              });
          })
        }
      });
    },
    fetchData(i) {
      let idx = i;
      if (i !== 0 && i !== 1) {
        this.targets.splice(0, 1, [])
        this.targets.splice(1, 1, [])
        idx = 0;
      }
      fetchTargets(this.listQuery[idx]).then(response => {
        if (response.success) {
          if (i !== 0 && i !== 1) {
            this.targets.splice(0, 1, response.result && response.result.items ? response.result.items : []);
            this.targets.splice(1, 1, response.result && response.result.items ? response.result.items : []);
          } else {
            this.targets.splice(i, 1, response.result && response.result.items ? response.result.items : []);
          }
          this.total = response.result.totalCount;
        }
      })
    },
    clickAdd() {
      if (this.form.targetIds.length >= 2) {
        return;
      }
      this.form.targetIds.push(1);
    },
    clickRemove(idx) {
      if (this.form.targetIds.length <= 1) {
        return;
      }
      this.form.targetIds.splice(idx, 1);
    },
    imageUrl(url, isExternal) {
      return imageUrl(url, isExternal);
    },
    handleChange(file, fileList) {
      this.fileList = fileList;
    },
    handleRemove(file, fileList) {
      this.fileList = fileList;
    },
  },
  mounted() {
    this.initForm();
    this.fetchData();
  }
};
</script>

<style lang="scss" scoped>
.morph-new {
  .form {
    margin-top: 25px;
  }

  /deep/ .el-upload--picture-card {
    width: 124px;
    height: 124px;
    line-height: 128px;
  }

  /deep/ .el-upload-list--picture-card .el-upload-list__item {
    width: 124px;
    height: 124px;
    line-height: 128px;
  }

  /deep/ .el-upload-list__item-status-label i {
    position: absolute;
    right: 15px
  }

  .target-container {
    margin-top: 15px;

    /deep/.el-card__header {
      padding: 5px 15px;
    }

    /deep/.el-card__body {
      padding: 5px;
    }

    .target-block {
      text-align: center;

      .target-img {
        max-width: 80px;
      }
    }

    .pagination-container {
      margin: 0;
      padding-top: 0;
      padding-bottom: 5px;
    }
  }
}
</style>